import annet from "./annet.svg";
import breederIcon from "./breeder.svg";
import dogface from "./dogface.svg";
import ernering from "./ernering.svg";
import helse from "./helse.svg";
import litterIcon from "./litterIcon.svg";
import logoutIcon from "./logout.svg";
import messagesIcon from "./messages.svg";
import miljotrening from "./miljotrening.svg";
import pawIcon from "./paw.svg";
import profileIcon from "./profile.svg";
import puppyLog from "./puppylog.svg";
import sosialisering from "./sosialisering.svg";
import stell from "./stell.svg";
import utvikling from "./utvikling.svg";
import fodsel from "./fodsel.svg";
import konkurranse from "./konkurranse.svg";
import parring from "./parring.svg";
import trening from "./trening.svg";
import temperatur from "./temperatur.svg";
import waitinglistIcon from "./waitinglist.svg";
import explore from "./explore.svg";

export {
  explore,
  annet,
  breederIcon,
  dogface,
  ernering,
  helse,
  litterIcon,
  logoutIcon,
  messagesIcon,
  miljotrening,
  pawIcon,
  profileIcon,
  puppyLog,
  sosialisering,
  stell,
  utvikling,
  waitinglistIcon,
  parring,
  trening,
  konkurranse,
  fodsel,
  temperatur,
};
