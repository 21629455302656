export enum State {
  Pending,
  Approved,
  Rejected,
}

export enum Type {
  Breeder,
  Litter,
  Dog,
  Puppy,
}
